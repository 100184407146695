<template>
  <div>
    <BaseSection
      :id="section.key"
      :is-visible="isSectionVisible"
      :is-modified="false"
      :section="section"
    >
      <template #section>
        <v-file-input
          class="files-input"
          :disabled="fileInProcess || FIELDS_DISABLED"
          multiple
          show-size
          counter
          :loading="fileInProcess"
          chips
          label="Application attachments"
          v-model="uploadedFiles"
        />

        <v-expand-transition>
          <v-card
            class="my-2"
            elevation="0"
            v-show="isUploadedFilesExist"
            outlined
            tile
            :disabled="FIELDS_DISABLED"
          >
            <v-card-title>
              Files to upload
            </v-card-title>

            <v-card-text>
              <v-simple-table>
                <template #default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      File Name
                    </th>
                    <th class="text-left">
                      File Description
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                    v-for="(file, fileIdx) in uploadedFiles"
                    :key="fileIdx"
                  >
                    <td>{{ file.name }}</td>
                    <td>
                      <v-combobox
                        clearable
                        v-model="uploadedFilesDescription[fileIdx]"
                        :items="descriptions"
                        placeholder="Ex: Payslip, contract, bank statement etc"
                        :disabled="FIELDS_DISABLED"
                      />
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>

            <v-card-actions>
              <v-btn
                v-if="!fileInProcess"
                color="primary"
                @click.prevent="uploadFiles"
                :disabled="FIELDS_DISABLED"
              >
                Upload
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expand-transition>
        <div class="files">
          <v-subheader>YOUR FILES</v-subheader>
          <div class="files-table">
            <div class="files-table__header">
              <span class="files-table__label text-center col-3">File Name</span>
              <span class="files-table__label text-center col-3">File Description</span>
              <span class="files-table__label text-center col-2">Size</span>
              <span class="files-table__label text-center col-2">Created At</span>
              <span class="files-table__label text-center col-1"></span>
              <span class="files-table__label text-center col-1"></span>
            </div>

            <div class="files-table__content">
              <Draggable
                v-model="storedFilesDraggable"
                @start="isDragging = true"
                @end="isDragging = false"
                v-bind="dragOptions"
              >
                <div
                  v-for="(file, fileIdx) in sortedStoredFiles"
                  :key="fileIdx"
                  class="files-table__item"
                >
                  <div class="col-3">{{ file.filename }}</div>
                  <div class="col-3">
                    <v-text-field class="without-indents"
                      v-model="file.description"
                      @blur="fileDescriptionEdit(file)"
                      type="text"
                    ></v-text-field>
                  </div>
                  <div class="col-2">{{ file.size | formatBytes }}</div>
                  <div class="col-2">{{ file.timeCreated }}</div>
                  <div class="col-1">
                    <v-btn
                      :disabled="fileInProcess"
                      @click.prevent="download(file)"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </div>
                  <div class="col-1 btn-group">
                      <v-btn
                      icon
                      :disabled="fileInProcess || FIELDS_DISABLED"
                      @click.prevent="exceptToggle(file, fileIdx)"
                    >
                      <v-icon
                        v-if="file.print === 'yes'"
                        color="primary"
                      >
                        mdi-check
                      </v-icon>
                      <v-icon v-else>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      :disabled="fileInProcess || FIELDS_DISABLED"
                      @click.prevent="moveToArchive(file)"
                    >
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                  </div>
                </div>
              </Draggable>
            </div>
          </div>
        </div>
      </template>
    </BaseSection>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

import Draggable from 'vuedraggable';
import SectionMixin from '@/mixins/SectionMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';
// eslint-disable-next-line import/named
import {
  FILE_STATUS,
  uploadFile as uploadFileToStorage,
  updateFile as updateFileToStorage,
  downloadFile,
  saveOrderFile,
  getFileByApplicationId,
  archiveFile,
} from '@/services/firebase';

export default {
  name: 'FileManager',

  components: {
    Draggable,
    BaseSection,
  },

  mixins: [SectionMixin],

  props: {
    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSectionVisible: false,

      isDragging: false,

      filesCount: 0,

      storedFiles: [],

      uploadedFiles: [],
      uploadedFilesDescription: [],

      fileInProcess: false,

      needSaveFilesOrder: false,

      descriptions: [
        'Identification',
        { divider: true },
        { header: 'Income' },
        'Boarder income letter',
        'Working for Families Entitlement confirmation',
        'Payslips',
        'Letter from Employer confirming Remuneration, Title and Length of Service',
        'Employment Contract or Letter of Offer of Employment',
        'Finance Accounts',
        'Tenancy Agreements',
        'Rental Appraisal',
        'IRD Summary of Earning',
        { divider: true },
        { header: 'Security' },
        'Sale and Purchase Agreement',
        'Register Valuations',
        'Government / Rateable Valuation',
        'Building Report',
        { divider: true },
        { header: 'Equity / Banks Statements' },
        'Kiwisaver Confirmation',
        'Gift Declaration',
        'Personal Current Account',
        'Savings Account',
        'Credit Cards',
        'Rental Account',
        'Business Bank Account',
        'Existing Loan Transaction History Including Latest balance',
        { divider: true },
        { header: 'Other' },
        'Loan Recommendation / Dairy Notes',
        'Lenders Debt Servicing Spreadsheet / Loan Worksheet',
        'Signed Declaration Form',
        'Credit Report',
        'Superannuation Balance',
        'Evidence of Shares and Other Investments',
        'Other Personal Loan Statements and Confirmation of Outstanding Balance',
        'Construction Contract / Building Quote',
        'Family Trust Deed',
        'Certificate of Incorporation for LTC Company (Investment Property)',
        'Other Documents',
      ],
    };
  },

  computed: {
    ...mapGetters([
      'APPLICATION_ID',
      'FIELDS_DISABLED',
    ]),

    sortedStoredFiles() {
      const files = this.sortStoredFiles();

      return files;
    },

    isUploadedFilesExist() {
      const isUploadedFilesExist = Boolean(this.uploadedFiles?.length > 0);

      return isUploadedFilesExist;
    },

    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },

    storedFilesDraggable: {
      get() {
        return this.sortedStoredFiles;
      },

      set(sortedFiles) {
        const saveDelay = 1000;

        const setNewOrderInFile = (file, idx) => {
          const orderFile = { ...file };
          orderFile.order = idx;

          return orderFile;
        };

        const newSortedFiles = sortedFiles.map(setNewOrderInFile);

        this.storedFiles = [...newSortedFiles];

        this.needSaveFilesOrder = true;

        setTimeout(this.saveFilesOrder(this.storedFiles), saveDelay);
      },
    },
  },

  async created() {
    await this.checkIsSectionVisible();

    await this.getFiles();
    this.clearUploadedFiles();

    this.$emit('componentReady');
  },

  methods: {
    sortStoredFiles() {
      const sortFiles = (firstFile, secondFile) => firstFile.order - secondFile.order;

      const files = [...this.storedFiles];
      files.sort(sortFiles);

      return files;
    },

    saveFilesOrder(files) {
      const isNotNeedSaveFilesOrder = Boolean(!this.needSaveFilesOrder);
      if (isNotNeedSaveFilesOrder) {
        return;
      }

      files.forEach((item) => {
        saveOrderFile(item);
      });
    },

    async uploadFiles() {
      this.fileInProcess = true;

      const uploadFile = async (file, idx) => {
        const fileMetadata = {
          customMetadata: {
            filename: file.name,
            description: this.uploadedFilesDescription[idx] ?? '',
            status: FILE_STATUS.active,
            order: idx + this.filesCount,
            print: 'yes',
          },
        };

        const filePayload = {
          file,
          metadata: fileMetadata,
          applicationId: this.APPLICATION_ID,
        };

        const fileResponse = await uploadFileToStorage(filePayload);

        await this.setFileToStoredFiles(fileResponse.metadata);
      };

      for (let i = 0; i < this.uploadedFiles.length; i += 1) {
        await uploadFile(this.uploadedFiles[i], i);
      }

      this.clearUploadedFiles();

      this.fileInProcess = false;
    },

    setFileToStoredFiles(fileMetadata) {
      const fileName = fileMetadata?.customMetadata?.filename ?? fileMetadata?.filename;
      const isFileNotActive = Boolean(fileMetadata?.customMetadata?.status !== FILE_STATUS.active);

      if (isFileNotActive) {
        return;
      }

      const fileData = {
        path: fileMetadata.fullPath,
        size: fileMetadata.size,
        contentType: fileMetadata.contentType,
        timeCreated: fileMetadata.timeCreated,
        filename: fileName,
        description: fileMetadata?.customMetadata?.description ?? '-',
        order: fileMetadata?.customMetadata?.order,
        print: fileMetadata?.customMetadata?.print,
      };

      this.storedFiles.push(fileData);
    },

    removeFromStoredFiles(removedFile) {
      const findFileByPath = (file) => file.path === removedFile.path;

      const fileIndex = this.storedFiles.findIndex(findFileByPath);

      this.storedFiles.splice(fileIndex, 1);
    },

    async getFiles() {
      const files = await getFileByApplicationId(this.APPLICATION_ID);

      const setStoredFile = (file) => {
        file.getMetadata().then(this.setFileToStoredFiles);
      };

      this.storedFiles = [];
      files.items.forEach(setStoredFile);
    },

    clearUploadedFiles() {
      this.uploadedFiles = [];
    },

    async exceptToggle(file, index) {
      const printStatus = (this.sortedStoredFiles[index].print === 'yes') ? 'no' : 'yes';

      this.sortedStoredFiles[index].print = printStatus;

      const fileMetadata = {
        customMetadata: {
          filename: file.filename,
          description: file.description,
          status: FILE_STATUS.active,
          order: file.order,
          print: printStatus,
        },
      };

      const filePayload = {
        metadata: fileMetadata,
        path: file.path,
      };

      await updateFileToStorage(filePayload);
    },

    async moveToArchive(file) {
      this.fileInProcess = true;
      await archiveFile(file);

      await this.removeFromStoredFiles(file);
      this.fileInProcess = false;
    },

    async download(file) {
      const fileDownload = downloadFile(file.path);
      const url = await fileDownload.getDownloadURL();

      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('href', url);
      downloadLink.setAttribute('target', '_blank');
      downloadLink.setAttribute('download', file.filename);

      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);

      downloadLink.click();
      document.body.removeChild(downloadLink);
    },

    changeFileOrder({ relatedContext, draggedContext }) {
      this.needSaveFilesOrder = false;

      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;

      const isNotRelatedElement = Boolean(!relatedElement || !relatedElement.fixed);
      const isFileOrderChanged = Boolean(isNotRelatedElement && !draggedElement.fixed);

      return isFileOrderChanged;
    },

    async fileDescriptionEdit(file) {
      const fileMetadata = {
        customMetadata: {
          filename: file.filename,
          description: file.description,
          status: FILE_STATUS.active,
          order: file.order,
          print: file.print,
        },
      };
      const filePayload = {
        metadata: fileMetadata,
        path: file.path,
      };
      await updateFileToStorage(filePayload);
    },
  },
};
</script>

<style scoped lang="scss">
.btn-group {
  display: flex;
}

.ghost {
  opacity: 0.5;
  background: #bbb;
}

.files-input {
  padding-left: 16px;
  padding-right: 16px;
}

.flip-list-move {
  transition: transform 0.3s;
}

.without-indents {
  margin: 0;
  padding: 0;
}

.no-move {
  transition: transform 0s;
}

.list-group-item {
  cursor: move;
}

.files {
  display: flex;
  flex-direction: column;

  &-table {
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 0;
    }
  }
}
</style>
